import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    services: [],
    servicesCount: 0,
    counters: [],
    dataLoading: false,
    service: {
      id: null,
      appointment_date: null,
      appointment_time: null,
      invoice_number: null,
      km: null,
      muayene: null,
      kasko: null,
      sigorta: null,
      egzoz: null,
      apply_form: null,
      belongings: null,
      rental: null,
      vale: null,
      customer_request: null,
      interview_number: null,
      cancel_content: null,
      infomailstatus: null,
      id_com_customer: null,
      id_com_brand: null,
      id_com_cars: null,
      id_com_service_type: null,
      id_com_location: null,
      id_com_meet: null,
      id_com_user: null,
      id_com_service_status: null,
      id_com_open_user: null,
      submitStatus: false,
    },
    serviceSave: {
      message: null,
      status: null,
      id: null,
    },
    filterData: {
      id_com_brand: [],
      id_com_service_status: ['1'],
      id_com_service_type: [],
      id_com_user: [],
      id_com_open_user: [],
      id_com_location: [],
      sdate: null,
      edate: null,
      detailSearch: null,
    },
    quickSearch: {
      keyword: '',
    },
    timesData: {
      data: [],
      serviceData: [],
    },
    serviceControl: {
      id: null,
      status: false,
    },
  },
  getters: {
    getServices(state) {
      return state.services
    },
    getServicesCount(state) {
      return state.servicesCount
    },
    getCounters(state) {
      return state.counters
    },
    getService(state) {
      return state.service
    },
    getServiceControl(state) {
      return state.serviceControl
    },
    getServiceSaveStatus(state) {
      return state.serviceSave
    },
    getFilterData(state) {
      return state.filterData
    },
    getQuickSearch(state) {
      return state.quickSearch
    },
    getTimesData(state) {
      return state.timesData
    },
    getDataLoading(state) {
      return state.dataLoading
    },
  },
  mutations: {
    SERVICES_LIST(state, data) {
      state.services = data
    },
    SERVICES_COUNTERS(state, data) {
      state.counters = data
    },
    SERVICE_VIEW(state, data) {
      state.service = data
    },
    SERVICES_COUNT(state, data) {
      state.servicesCount = data
    },
    SERVICE_SAVE(state, data) {
      state.serviceSave = data
    },
    SET_QUICK_SEARCH(state, data) {
      state.quickSearch = data
    },
    SET_TIMES(state, data) {
      state.timesData = data
    },
    SERVICE_CONTROL(state, data) {
      state.serviceControl = data
    },
    RESET_ITEM_DATA(state) {
      state.serviceControl = {
        id: null,
        status: false,
      }
      state.service = {
        id: null,
        appointment_date: null,
        appointment_time: null,
        invoice_number: null,
        km: null,
        muayene: null,
        kasko: null,
        sigorta: null,
        egzoz: null,
        apply_form: null,
        belongings: null,
        rental: null,
        vale: null,
        customer_request: null,
        interview_number: null,
        cancel_content: null,
        infomailstatus: null,
        id_com_customer: null,
        id_com_brand: null,
        id_com_cars: null,
        id_com_service_type: null,
        id_com_location: null,
        id_com_meet: null,
        id_com_user: null,
        id_com_service_status: null,
        id_com_open_user: null,
        submitStatus: false,
      }
    },
    RESET_FILTER_DATA(state) {
      state.filterData = {
        id_com_brand: [],
        id_com_service_status: [],
        id_com_service_type: [],
        id_com_user: [],
        id_com_location: [],
        sdate: null,
        edate: null,
      }
    },
    SET_DATA_LOADING(state, status) {
      state.dataLoading = status
    },
  },
  actions: {
    servicesList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/service_tracing', data)
        .then(response => {
          commit('SERVICES_LIST', response.data.data)
          commit('SERVICES_COUNT', response.data.count)
          commit('SERVICES_COUNTERS', response.data.counters)
          commit('SET_DATA_LOADING', false)
        })
        .catch(error => console.error(error))
    },
    serviceView({ commit }, id) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify({
        where: {
          'com_service_tracing.id': id,
        },
        array: false,
      })
      return axiosIns
        .post('/data/service_tracing', data)
        .then(response => {
          commit('SERVICE_VIEW', response.data.data)
          commit('SET_DATA_LOADING', false)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    serviceControl({ commit }, carsId) {
      return axiosIns
        .post('/data/service_tracing/control', { id_com_cars: carsId })
        .then(response => {
          commit('SERVICE_CONTROL', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    serviceSave({ commit }, postData) {
      axiosIns
        .post('/data/service_tracing/saveData', postData)
        .then(response => {
          commit('SERVICE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    acceptedService({ commit }, postData) {
      axiosIns
        .post('/data/service_tracing/accepted', postData)
        .then(response => {
          commit('SERVICE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      return axiosIns
        .post('/data/service_tracing/deleteData', { id })
        .then(response => {
          commit('SERVICE_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    getTimes({ commit }, postData) {
      axiosIns
        .post('/data/service_tracing/getTimes', postData)
        .then(response => {
          commit('SET_TIMES', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
